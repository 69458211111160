import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Flex, Grid, Group, Paper, PinInput, Text, Title } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { maskEmail, maskMobile } from '../../utils/helpers';
import { verifyMobileOTPSchema, verifyOTPSchema } from '../../utils/schema/formSchema';
import { useLoginStyles } from './component/style';
import { mobileVerifyOTP, resendMobileVerifyOTP } from '../../services/authentication.api';
import { toastMessageUpdate } from '../../utils/helpers/tosterMessage';
import { InferType } from 'yup';
import { useDisclosure } from '@mantine/hooks';
import ModalOrDrawer from '../Dashboard/Customer/Profile/components/ModalOrDrawer';
import OtpVerificationSuccess from './OtpVerificationSuccess';

export default function MobileVerification() {
  const { classes } = useLoginStyles();
  const time = 120;
  const [remaingTime, setRemainingTime] = useState(time);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(verifyMobileOTPSchema),
    mode: 'all',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [reSendLoading, setReSendLoading] = useState(false);
  const [cookie, setCookie, removeCookie] = useCookies(['mobilenumber', 'emailAddress']);
  const navigate = useNavigate();

  useEffect(() => {
    //Implementing the setInterval method
    let interval: any = null;
    if (!isCompleted) {
      interval = setInterval(() => {
        setRemainingTime(remaingTime - 1);
        if (remaingTime < 1) {
          setIsCompleted(true);
          clearInterval(interval);
        }
      }, 1000);
    }

    //Clearing the interval
    return () => clearInterval(interval);
  }, [remaingTime]);

  useEffect(() => {
    reset({
      mobile: cookie['mobilenumber'] || '',
    });
  }, []);

  function formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  }

  const onSubmit: SubmitHandler<InferType<typeof verifyMobileOTPSchema>> = async (data) => {
    try {
      setIsLoading(true);
      const response = await mobileVerifyOTP(data);
      openOtpVerificationSuccess();
      setTimeout(() => {
        navigate('/login');
      }, 3000);
      setIsLoading(false);
      removeCookie('mobilenumber');
      removeCookie('emailAddress');
    } catch (error: any) {
      setIsLoading(false);
      toastMessageUpdate('error', error?.response?.data?.message);
    }
  };

  const resendOTPHandler = async () => {
    try {
      setReSendLoading(true);
      const data = await resendMobileVerifyOTP({ mobile: getValues('mobile') });
      setIsCompleted(false);
      setRemainingTime(time);
      setReSendLoading(false);
    } catch (error: any) {
      setReSendLoading(true);
      toastMessageUpdate('error', error?.response?.data?.message || 'Something went wrong.');
    }
  };

  const [otpVerificationSuccessIsOpened, { open: openOtpVerificationSuccess, close: closeOtpVerificationSuccess }] =
    useDisclosure(false);

  return (
    <>
      <ModalOrDrawer
        opened={otpVerificationSuccessIsOpened}
        onClose={closeOtpVerificationSuccess}
        drawerProps={{ dz: '250', drawerBackButton: true }}
        modalProps={{ modalCloseButton: true }}>
        <OtpVerificationSuccess />
      </ModalOrDrawer>
      <Grid w='100%'>
        <Grid.Col md={5} offsetMd={3.5}>
          <Paper withBorder shadow='md' p={30} radius='xl' mt='xl'>
            <Title order={2} align='center'>
              Validate Email or Mobile Number
            </Title>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
              {(cookie['mobilenumber'] || cookie['emailAddress']) && (
                <Flex justify='center' gap={5} direction='column'>
                  <Text fz='md' fw='bold' ta='center'>
                    OTP sent on your:
                  </Text>
                  {cookie['mobilenumber'] && (
                    <Group position='center' spacing={4}>
                      <Text fz='sm' c='orange' fw={600}>
                        Mobile Number:
                      </Text>
                      <Text fz='sm'>{maskMobile(getValues('mobile') || '')}</Text>
                    </Group>
                  )}

                  {cookie['emailAddress'] && (
                    <Group position='center' spacing={4}>
                      <Text fz='sm' c='orange' fw={600}>
                        Email Address:
                      </Text>
                      <Text fz='sm'>{maskEmail(cookie['emailAddress'] || '')}</Text>
                    </Group>
                  )}
                </Flex>
              )}

              <Box mb='lg' mt={16}>
                <Group position='center'>
                  <Controller
                    name='OTP_mobile'
                    control={control}
                    render={({ field }) => (
                      <PinInput error={errors?.OTP_mobile ? true : false} {...field} name='otp' length={6} size='lg' />
                    )}
                  />
                  {errors?.OTP_mobile && (
                    <Text fz={14} pt={5} pl={5} fw={500} color='red'>
                      {errors?.OTP_mobile?.message}
                    </Text>
                  )}
                </Group>
              </Box>
              <Button
                variant='gradient'
                gradient={{ from: '#fda223', to: '#fe7720' }}
                fullWidth
                size='md'
                mt={20}
                loading={isLoading}
                type='submit'
                radius='sm'>
                {!isLoading && 'Submit'}
              </Button>
              <Flex justify='center' gap={5} mt={15}>
                <Text c='dimmed' fz='sm' ta='center'>
                  Didn&apos;t get code yet?
                </Text>
                {!isCompleted && (
                  <Text c='orange' fz='sm' ta='center'>
                    {formatTime(remaingTime)}
                  </Text>
                )}
              </Flex>
              {isCompleted && (
                <Flex justify='center' gap={5} mt={15}>
                  <Button
                    c='orange'
                    variant='transparent'
                    loading={reSendLoading}
                    onClick={resendOTPHandler}
                    type='button'
                    className={classes.control}>
                    Re-send OTP
                  </Button>
                </Flex>
              )}
            </form>
          </Paper>
        </Grid.Col>
      </Grid>
    </>
  );
}
