import * as yup from 'yup';
import { ValidationErrorMessage, ValidationPattern } from './validationMessage';

export const signUpSchema = yup.object().shape({
  firstName: yup
    .string()
    .required(ValidationErrorMessage.First_Name_Required)
    .matches(ValidationPattern.characterOnly, ValidationErrorMessage.Valid_Name)
    .test('userName', ValidationErrorMessage.First_Name_2_Required, function (values: any) {
      if (values?.trim().length > 1) {
        return true;
      } else {
        return false;
      }
    }),
  lastName: yup
    .string()
    .required(ValidationErrorMessage.Last_Name_Required)
    .matches(ValidationPattern.characterOnly, ValidationErrorMessage.Valid_Name)
    .test('userName', ValidationErrorMessage.Last_Name__2_Required, function (values: any) {
      if (values?.trim().length > 1) {
        return true;
      } else {
        return false;
      }
    }),
  // .min(3, ValidationErrorMessage.User_Name_Min)
  // .max(50, ValidationErrorMessage.User_Name_Max),
  email: yup
    .string()
    .required(ValidationErrorMessage.Email_Required)
    .matches(ValidationPattern.emailPattern, ValidationErrorMessage.Email_Pattern)
    .max(50, ValidationErrorMessage.Email_Max),
  mobile: yup
    .string()
    .required(ValidationErrorMessage.Phone_Required)
    .min(8, ValidationErrorMessage.Phone_Min)
    .max(15, ValidationErrorMessage.Phone_Max)
    .matches(ValidationPattern.numberOnly, ValidationErrorMessage.Number_Only)
    .test('mobile', 'Please enter valid phone number.', function (values: any) {
      if (values?.trim().length > 0) {
        return true;
      } else {
        return false;
      }
    }),

  userType: yup.string().required(ValidationErrorMessage.User_Type_Required),
  password: yup
    .string()
    .required(ValidationErrorMessage.Password_Required)
    .matches(ValidationPattern.password, ValidationErrorMessage.Password_Pattern)
    .min(6, ValidationErrorMessage.Password_Min)
    .max(20, ValidationErrorMessage.Password_Max),
  termsCondition: yup.boolean().oneOf([true], ValidationErrorMessage.Terms_Condition_Required),
  receiveMessageEmailPhoneCall: yup.boolean().oneOf([true], ValidationErrorMessage.Allow_Message_Email_Call_Required),
});

export const signInSchema = yup.object().shape({
  email: yup
    .string()
    .required(ValidationErrorMessage.Email_Required)
    .matches(ValidationPattern.emailPattern, ValidationErrorMessage.Email_Pattern)
    .max(50, ValidationErrorMessage.Email_Max),
  password: yup.string().required(ValidationErrorMessage.Password_Required),
  // .matches(ValidationPattern.password, ValidationErrorMessage.Password_Pattern)
  // .max(20, ValidationErrorMessage.Password_Max),
  rememberMe: yup.boolean(),
});

export const forgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .required(ValidationErrorMessage.Email_Required)
    .matches(ValidationPattern.emailPattern, ValidationErrorMessage.Email_Pattern)
    .max(50, ValidationErrorMessage.Email_Max),
});

export const otpResendViaMobileSchema = yup.object().shape({
  mobile: yup
    .string()
    .required(ValidationErrorMessage.Phone_Required)
    .min(8, ValidationErrorMessage.Phone_Min)
    .max(15, ValidationErrorMessage.Phone_Max)
    .defined(),
});

export const verifyOTPSchema = yup.object().shape({
  email: yup.string(),
  otp: yup.string().required(ValidationErrorMessage.OTP_required),
});

export const resetpasswordSchema = yup.object().shape({
  email: yup.string(),
  password: yup
    .string()
    .required(ValidationErrorMessage.Password_Required)
    .matches(ValidationPattern.password, ValidationErrorMessage.Password_Pattern)
    .min(6, ValidationErrorMessage.Password_Min)
    .max(20, ValidationErrorMessage.Password_Max),
  confirm_password: yup
    .string()
    .required(ValidationErrorMessage.confirm_password_required)
    .oneOf([yup.ref('password')], ValidationErrorMessage.Password_Not_Match),
});

export const verifyMobileOTPSchema = yup.object().shape({
  mobile: yup.string(),
  OTP_mobile: yup.string().required(ValidationErrorMessage.OTP_required).min(6, ValidationErrorMessage.OTP_Min_Length),
});

export const profileOverviewSchema = yup.object().shape({
  profileSummery: yup.string(),
  userCity: yup.string(),
  userEmail: yup
    .string()
    // .required(ValidationErrorMessage.Email_Required)
    .matches(ValidationPattern.emailPattern, ValidationErrorMessage.Email_Pattern)
    .max(50, ValidationErrorMessage.Email_Max),
  userAddress: yup
    .string()
    // .required('Please enter your address')
    .max(1000, ValidationErrorMessage.Address_Max),
  phoneNumber: yup
    .string()
    .required(ValidationErrorMessage.Phone_Required)
    .matches(ValidationPattern.numberOnly, ValidationErrorMessage.Number_Only)
    .min(8, ValidationErrorMessage.Phone_Min)
    .max(15, ValidationErrorMessage.Phone_Max),
  userCountry: yup.string(),
  userState: yup.string(),
  gender: yup.string().required(ValidationErrorMessage.Gender_Required),
  // hourlyRate: yup.string().required('Please add your hourly rate.'),
  currency: yup.string().required(ValidationErrorMessage.Currency_Required),
  backgroundCheckVerify: yup.boolean(),
  experience: yup
    .string()
    .matches(ValidationPattern.numberOnly, ValidationErrorMessage.Number_Only)
    .max(2, ValidationErrorMessage.ExperienceCannotExceedTwoDigits),
});

export const orgProfileOverviewSchema = yup.object().shape({
  profileSummery: yup.string(),
  userEmail: yup
    .string()
    // .required(ValidationErrorMessage.Email_Required)
    .matches(ValidationPattern.emailPattern, ValidationErrorMessage.Email_Pattern)
    .max(50, ValidationErrorMessage.Email_Max),
  phoneNumber: yup
    .string()
    .required(ValidationErrorMessage.Phone_Required)
    .matches(ValidationPattern.numberOnly, ValidationErrorMessage.Number_Only)
    .min(8, ValidationErrorMessage.Phone_Min)
    .max(15, ValidationErrorMessage.Phone_Max),
  gender: yup.string().nullable(),
  // // hourlyRate: yup.string().required('Please add your hourly rate.'),
  currency: yup.string().required(ValidationErrorMessage.Currency_Required),
  backgroundCheckVerify: yup.boolean(),
  experience: yup
    .string()
    .matches(ValidationPattern.numberOnly, ValidationErrorMessage.Number_Only)
    .max(2, ValidationErrorMessage.ExperienceCannotExceedTwoDigits),
});

export const addressSchema = yup.object().shape({
  userCity: yup.string(),
  placeAddress: yup.string(), //.required('Please enter your address'),
  userAddress: yup
    .string()
    .required(ValidationErrorMessage.Address_Required)
    .max(1000, ValidationErrorMessage.Address_Max)
    .test('userAddress', 'Please add some value.', function (values: any) {
      if (values !== undefined && values?.trim().length > 0) {
        return true;
      } else {
        return false;
      }
    }),
  userCountry: yup.string().required(ValidationErrorMessage.Country_Required),
  userState: yup.string().required(ValidationErrorMessage.State_Required),
  userZipCode: yup
    .string()
    .required(ValidationErrorMessage.Zip_Required)
    // .matches(ValidationPattern.pinCodePattern, ValidationErrorMessage.Valid_Zipcode)
    .matches(ValidationPattern.numberOnly, ValidationErrorMessage.Number_Only)
    .min(5, ValidationErrorMessage.Valid_Zipcode)
    .max(7, ValidationErrorMessage.Valid_Zipcode),
  // .test('userZipCode', 'Please add some value.', function (values: any) {
  //   console.log('values', values);
  //   if (values !== undefined && values?.trim().length > 0) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }),
});

export const profileUpdateSchema = yup.object().shape({
  userName: yup
    .string()
    .required(ValidationErrorMessage.User_Name_Required)
    .matches(ValidationPattern.characterOnly, ValidationErrorMessage.Valid_Name),
  userCity: yup.string(),
  userDesignation: yup
    .string()
    .matches(ValidationPattern.characterOnlyWithComma, ValidationErrorMessage.CharacterOnlyWithComma),
  // .required(ValidationErrorMessage.Profile_Designation_Required),
});

export const providerProfileUpdateNameSchema = yup.object().shape({
  firstName: yup
    .string()
    .required(ValidationErrorMessage.User_Name_Required)
    .matches(ValidationPattern.characterOnly, ValidationErrorMessage.Valid_Name),
  lastName: yup
    .string()
    .required(ValidationErrorMessage.User_Name_Required)
    .matches(ValidationPattern.characterOnly, ValidationErrorMessage.Valid_Name),
});

export const providerProfileUpdateSkillSchema = yup.object().shape({
  skills: yup.string().matches(ValidationPattern.characterOnlyWithComma, ValidationErrorMessage.CharacterOnlyWithComma),
});

export const serviceUpdateSchema = yup.object().shape({
  serviceTitle: yup.string(),
  description: yup.string().required(ValidationErrorMessage.ServiceDescription_Required),
  rate_per_hour: yup
    .string()
    .required(ValidationErrorMessage.ServiceCharges_Required)
    .matches(ValidationPattern.numberOnly, ValidationErrorMessage.Number_Only)
    .test('minRate', ValidationErrorMessage.Minimum_Service_Charges, function (values: any) {
      if (+values?.trim() >= 5) {
        return true;
      } else {
        return false;
      }
    })
    .test('maxRate', ValidationErrorMessage.Maximum_Service_Charges, function (value: any) {
      return +value?.trim() > 999 ? false : true;
    }),
  service_type_id: yup.string(),
  // serviceImage: yup.mixed(),
});

export const filterSearchSchema = yup.object().shape({
  // categories: yup.string(),
  // keywords: yup.string(),
  // location: yup.string(),
  // completeStatus: yup.string(),
  // availability: yup.array().of(yup.string()),
  // currency: yup.string(),
  category: yup.string().nullable(),
  location: yup.string().nullable(),
  priceRateRange: yup.mixed(),
  experience: yup.mixed(),
  range: yup.string(),
});

export const serviceDetailsSchema = yup.object().shape({
  name: yup
    .string()
    .required(ValidationErrorMessage.User_Name_Required)
    .matches(ValidationPattern.characterOnly, ValidationErrorMessage.Valid_Name),
  phoneNumber: yup
    .string()
    .required(ValidationErrorMessage.Phone_Required)
    .matches(ValidationPattern.numberOnly, ValidationErrorMessage.Number_Only)
    .max(15, ValidationErrorMessage.Phone_Max),
  email: yup
    .string()
    .required(ValidationErrorMessage.Email_Required)
    .matches(ValidationPattern.emailPattern, ValidationErrorMessage.Email_Pattern)
    .max(50, ValidationErrorMessage.Email_Max),
  address: yup.string().required(ValidationErrorMessage.Address_Required),
  providers_quantity: yup
    .string()
    .required('This is a required field')
    .test('maxProvider', ValidationErrorMessage.ServiceProvidersCannotExceedThreeDigits, function (value) {
      return +value.trim() > 999 ? false : true;
    })
    .test('cannotStartWithZero', ValidationErrorMessage.CannotStartWithZero, function (value) {
      return +value.trim().startsWith('0') ? false : true;
    }),
  // booking_type: yup.string().required(ValidationErrorMessage.User_Name_Required),
});

export const addUserSchema = yup.object().shape({
  firstName: yup
    .string()
    .required(ValidationErrorMessage.First_Name_Required)
    .matches(ValidationPattern.characterOnly, ValidationErrorMessage.Valid_Name)
    .test('firstNameCheck', ValidationErrorMessage.First_Name_2_Required, function (values: any) {
      if (values?.trim().length > 1) {
        return true;
      } else {
        return false;
      }
    }),
  lastName: yup
    .string()
    .required(ValidationErrorMessage.Last_Name_Required)
    .matches(ValidationPattern.characterOnly, ValidationErrorMessage.Valid_Name)
    .test('lastNameCheck', ValidationErrorMessage.Last_Name__2_Required, function (values: any) {
      if (values?.trim().length > 1) {
        return true;
      } else {
        return false;
      }
    }),
  email: yup
    .string()
    .required(ValidationErrorMessage.Email_Required)
    .matches(ValidationPattern.emailPattern, ValidationErrorMessage.Email_Pattern)
    .max(50, ValidationErrorMessage.Email_Max),
  mobile: yup
    .string()
    .required(ValidationErrorMessage.Phone_Required)
    .min(8, ValidationErrorMessage.Phone_Min)
    .max(15, ValidationErrorMessage.Phone_Max)
    .matches(ValidationPattern.numberOnly, ValidationErrorMessage.Number_Only)
    .test('mobile', 'Please enter valid phone number.', function (values: any) {
      if (values?.trim().length > 0) {
        return true;
      } else {
        return false;
      }
    }),
  password: yup
    .string()
    .required(ValidationErrorMessage.Password_Required)
    .matches(ValidationPattern.password, ValidationErrorMessage.Password_Pattern)
    .min(6, ValidationErrorMessage.Password_Min)
    .max(20, ValidationErrorMessage.Password_Max),
  // confirmPassword: yup
  //   .string()
  //   .oneOf([yup.ref('password'), null], 'Passwords must match')
});

export const customerProfileSchema = yup.object().shape({
  email: yup
    .string()
    // .required(ValidationErrorMessage.Email_Required)
    .matches(ValidationPattern.emailPattern, ValidationErrorMessage.Email_Pattern)
    .max(50, ValidationErrorMessage.Email_Max),
  phoneNumber: yup
    .string()
    .required(ValidationErrorMessage.Phone_Required)
    .matches(ValidationPattern.numberOnly, ValidationErrorMessage.Number_Only)
    .min(8, ValidationErrorMessage.Phone_Min)
    .max(15, ValidationErrorMessage.Phone_Max),
  gender: yup.string().required(ValidationErrorMessage.Gender_Required),
  profileName: yup.string(),
  // .required(ValidationErrorMessage.Profile_Name_Required),
  // .test('userName', 'Please enter your name.', function (values: any) {
  //   if (values?.trim().length > 0) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }),
  // .matches(ValidationPattern.characterOnly, ValidationErrorMessage.Valid_Name),
});

export const orgCustomerProfileSchema = yup.object().shape({
  email: yup
    .string()
    // .required(ValidationErrorMessage.Email_Required)
    .matches(ValidationPattern.emailPattern, ValidationErrorMessage.Email_Pattern)
    .max(50, ValidationErrorMessage.Email_Max),
  phoneNumber: yup
    .string()
    .required(ValidationErrorMessage.Phone_Required)
    .matches(ValidationPattern.numberOnly, ValidationErrorMessage.Number_Only)
    .min(8, ValidationErrorMessage.Phone_Min)
    .max(15, ValidationErrorMessage.Phone_Max),
  gender: yup.string().nullable(),
  profileName: yup.string(),
});

export const paymentSchema = yup.object().shape({
  first_name: yup
    .string()
    .required(ValidationErrorMessage.First_Name_Required)
    .matches(ValidationPattern.characterOnly, ValidationErrorMessage.Valid_Name)
    .test('emptyFirstName', ValidationErrorMessage.First_Name_Required, function (values: any) {
      if (values?.trim().length > 0) {
        return true;
      } else {
        return false;
      }
    }),
  last_name: yup
    .string()
    .required(ValidationErrorMessage.Last_Name_Required)
    .matches(ValidationPattern.characterOnly, ValidationErrorMessage.Valid_Name)
    .test('emptyLastName', ValidationErrorMessage.Last_Name_Required, function (values: any) {
      if (values?.trim().length > 0) {
        return true;
      } else {
        return false;
      }
    }),
  card_number: yup
    .string()
    .required(ValidationErrorMessage.Card_Number_Required)
    .length(16, ValidationErrorMessage.Card_Number_Length)
    .matches(ValidationPattern.numberOnly, ValidationErrorMessage.Valid_Card_Number),
  expiry_month: yup.date().required(ValidationErrorMessage.Expiry_Month),
  expiry_year: yup.date().typeError('afssf').required(ValidationErrorMessage.Expiry_Year),
  cvv: yup
    .string()
    .required(ValidationErrorMessage.Cvv_Required)
    .matches(ValidationPattern.numberOnly, ValidationErrorMessage.Valid_CVV_Number),
});

export const organizationInfoSchema = yup.object().shape({
  name: yup.string().required(ValidationErrorMessage.Org_Name_Required),
  description: yup.string().max(1000, ValidationErrorMessage.Max_Description),
});

export const immediateBookingSchema = yup.object().shape({
  providers_quantity: yup
    .string()
    .required(ValidationErrorMessage.Quantity_Required)
    .matches(ValidationPattern.numberOnly, ValidationErrorMessage.Number_Only)
    .matches(
      ValidationPattern.numberOnlyButMustNotStartWithZero,
      ValidationErrorMessage.NumberOnlyButMustNotStartWithZero,
    )
    .max(3, ValidationErrorMessage.ServiceProvidersCannotExceedThreeDigits),
});

export const basicPaymentSchema = yup.object().shape({
  first_name: yup
    .string()
    .required(ValidationErrorMessage.First_Name_Required)
    .matches(ValidationPattern.characterOnly, ValidationErrorMessage.Valid_Name)
    .test('emptyFirstName', ValidationErrorMessage.First_Name_2_Required, function (values: any) {
      if (values?.trim().length > 1) {
        return true;
      } else {
        return false;
      }
    }),
  last_name: yup
    .string()
    .required(ValidationErrorMessage.Last_Name_Required)
    .matches(ValidationPattern.characterOnly, ValidationErrorMessage.Valid_Name)
    .test('emptyLastName', ValidationErrorMessage.Last_Name__2_Required, function (values: any) {
      if (values?.trim().length > 1) {
        return true;
      } else {
        return false;
      }
    }),
});

export const rateAndReviewSchema = yup.object().shape({
  rating: yup.number().min(1).max(5).required(ValidationErrorMessage.RATING_REQUIRED),
  review: yup.string().optional(),
});

export const questionSchema = (questions: any) => {
  const textQuestions = questions?.filter((q: any) => q.question_type === 'text');
  const mcqQuestions = questions?.filter((q: any) => q.question_type === 'mcq');

  const schema = yup.object().shape({
    jobTitle: yup
      .string()
      .required(ValidationErrorMessage.Job_Title_Required)
      .matches(ValidationPattern.characterOnly, ValidationErrorMessage.Valid_Name)
      .test('job', ValidationErrorMessage.Job_Title_Length_Required, function (values: any) {
        if (values?.trim().length > 4) {
          return true;
        } else {
          return false;
        }
      }),
    jobDescription: yup
      .string()
      .required(ValidationErrorMessage.Job_Description_Required)
      .matches(ValidationPattern.characterOnly, ValidationErrorMessage.Valid_Name)
      .test('job', ValidationErrorMessage.Job_Description_Length_Required, function (values: any) {
        if (values?.trim().length > 14) {
          return true;
        } else {
          return false;
        }
      }),
    jobBudget: yup
      .string()
      .required(ValidationErrorMessage.Job_Budget_Required)
      .min(2, ValidationErrorMessage.Budget_Min)
      .max(6, ValidationErrorMessage.Budget_Max)
      .matches(ValidationPattern.numberOnly, ValidationErrorMessage.Number_Only)
      .test('budget', 'Please enter valid number.', function (values: any) {
        if (values?.trim().length > 0) {
          return true;
        } else {
          return false;
        }
      }),
    ...textQuestions?.reduce((acc: any, question: any) => {
      acc[`questionsText${question.id}`] = yup.string().required(ValidationErrorMessage.Questions_Text);
      return acc;
    }, {}),
    ...mcqQuestions?.reduce((acc: any, question: any) => {
      acc[`questionsOption${question.id}`] = yup.string().required(ValidationErrorMessage.Questions_Option);
      return acc;
    }, {}),
  });

  return schema;
};
