import { useQuery } from '@tanstack/react-query';
import { catchHandler } from '../utils/helpers';
import { axiosTokenInst } from './axios.config';

export const createJob = catchHandler((formData: any) => {
  return axiosTokenInst.post(`job-portal/jobs`, formData);
});

export const getQuestion = catchHandler(async (id: any) => {
  return await axiosTokenInst.get(`job-portal/questions-by-service-type/${id}`);
});

export const getQuestionByServicetype = (serviceId: any) => {
  return useQuery({
    queryKey: ['create-question'],
    queryFn: async () =>
      await getQuestion(serviceId).then((response: any) => {
        return response;
      }),
  });
};

export const getJob = catchHandler(async (userType) => {
  return await axiosTokenInst.get(userType === 'user' ? `job-portal/jobs/user` : `job-portal/jobs/available-jobs`);
});

export const getJobByUserId = (userType: string) => {
  return useQuery({
    queryKey: ['create-question'],
    queryFn: async () =>
      await getJob(userType).then((response: any) => {
        return response;
      }),
  });
};

export const applyJobService = catchHandler((formData: any) => {
  return axiosTokenInst.post(`job-portal/applications`, formData);
});
