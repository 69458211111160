import { Box, Button, Divider, Flex, Grid, ScrollArea, Title } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import GlobalLoader from '../../../../../components/GlobalLoader';
import RelatedProfiles from '../../../../../components/RelatedProfiles';
import {
  addFavouriteProvider,
  deletegetFavouriteProvider,
  getFavouriteProviderDetails,
  useFavouriteList,
} from '../../../../../services/favourite.api';
import { reviewList } from '../../../../../services/review';
import { useUserServiceById } from '../../../../../services/service';
import { useUserOverview } from '../../../../../services/userProfiles';
import { getVerifications } from '../../../../../services/verification';
import { ReviewType, RoleType, customerRole, providerRole } from '../../../../../utils';
import decodeUserIdFromRandomizedString from '../../../../../utils/helpers/decodeUserIdFromRandomizedString';
import { toastMessageUpdate } from '../../../../../utils/helpers/tosterMessage';
import { CommentCard } from '../../../UserProfile/components/CommentCard';
import { TabElementDetails } from '../../../UserProfile/components/TabElementDetails';
import AddToFavourites from './AddToFavourites';
import ProfileCard from './ProfileCard';
import UserBasicInfo from './UserBasicInfo';
import { useCommonUserViewProfileStyles } from './style';

type ProfileType = 'cust' | 'prov';

export default function CommonUserViewProfile() {
  const { encodedIdWithRandomString } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [cookie, setCookie] = useCookies(['user_Info', 'role']);

  const { classes } = useCommonUserViewProfileStyles();

  const userId = decodeUserIdFromRandomizedString(encodedIdWithRandomString);

  const { pathname, search } = useLocation();

  let profileType: ProfileType | undefined = undefined;
  const result = pathname.match(/\/(.*?)\//);
  if (result !== null) {
    if (result[1] === 'prov' || result[1] === 'cust') {
      profileType = result[1];
    } else {
      navigate('*');
    }
  }
  console.log(profileType);

  const [verificationData, setVerificationData] = useState<Record<string, any> | null>(null);
  const [userFeedback, setUserFeedback] = useState<Array<Record<string, any>> | null>(null);
  const [verificationDataIsLoading, setVerificationDataIsLoading] = useState(false);
  const [userFeedbackDataIsLoading, setUserFeedbackDataIsLoading] = useState(false);
  const [favouritesIsLoading, setFavouritesIsLoading] = useState(false);

  const [favouriteUserDetails, setFavouriteUserDetails] = useState<Record<string, any> | null>(null);

  const userIsViewingOwnProfile = +userId === +cookie.user_Info.id;

  const { data: userOverViewData, isLoading } = useUserOverview(userId);
  console.group('USER OVERVIEW');
  console.log(userOverViewData?.data);
  console.groupEnd();

  console.group('VERIFICATION DATA');
  console.log(verificationData);
  console.groupEnd();

  const { data: favouritesList } = useFavouriteList();

  const { data: serviceData, isLoading: serviceDataIsLoading } = useUserServiceById(userId, (data) =>
    console.log(data),
  );

  useEffect(() => {
    (async function () {
      await fetchVerificationStatusForThisUser();
      await fetchUserFeedback();
      await fetchFavourites();
    })();

    if (userOverViewData && Object.hasOwn(userOverViewData?.data, 'user')) {
      if (profileType === 'prov' && !providerRole.includes(userOverViewData?.data?.user?.type)) {
        navigate('*', { replace: true, relative: 'path' });
      }

      if (profileType === 'cust' && !customerRole.includes(userOverViewData?.data?.user?.type)) {
        navigate('*');
      }
    }
  }, [userOverViewData]);

  async function fetchFavourites() {
    const data = await getFavouriteProviderDetails();
    if (data.data?.length) {
      const favouriteUserIndex = data.data?.findIndex((item: any) => +item.fav_user_id === +userId);
      if (favouriteUserIndex === -1) setFavouriteUserDetails(null);
      else {
        setFavouriteUserDetails(data.data[favouriteUserIndex]);
      }
    }
  }

  async function fetchUserFeedback() {
    try {
      setUserFeedbackDataIsLoading(true);
      const review = await reviewList(ReviewType.user, userId);
      setUserFeedbackDataIsLoading(false);
      setUserFeedback(review.data);
    } catch (error) {
      setUserFeedbackDataIsLoading(false);
      console.log(error);
    }
  }

  console.log(userFeedback);

  async function fetchVerificationStatusForThisUser() {
    try {
      setVerificationDataIsLoading(true);
      const data = await getVerifications(userId);
      setVerificationDataIsLoading(false);
      console.group('VERIFICATION DATA');
      console.log(data.data);
      setVerificationData(data.data);
      console.groupEnd();
    } catch (error) {
      console.error(error);
      setVerificationDataIsLoading(false);
      setVerificationData(null);
    }
  }

  const renderAddToFavourites = () => {
    if (userIsViewingOwnProfile) return false;
    else {
      if (customerRole.includes(+cookie.role)) {
        return true;
      } else false;
    }
  };

  const handleFavouritesAddOrRemove = async (type: 'add' | 'remove') => {
    let result: any;
    setFavouritesIsLoading(true);
    switch (type) {
      case 'add':
        try {
          result = await addFavouriteProvider({ user_id: userId });
          await fetchFavourites();
          setFavouritesIsLoading(false);
          toastMessageUpdate('success', result?.message);
        } catch (error: any) {
          setFavouritesIsLoading(false);
          console.log(error);
        }

        break;
      case 'remove':
        try {
          result = await deletegetFavouriteProvider({ favourite_id: favouriteUserDetails?.['favourite_id'] });
          await fetchFavourites();
          setFavouritesIsLoading(false);
          toastMessageUpdate('success', result?.message);
        } catch (error: any) {
          setFavouritesIsLoading(false);
          toastMessageUpdate('error', error?.data?.message);
        }

        break;
    }
  };

  const handleBookNow = () => {
    navigate(`/book-services/${encodedIdWithRandomString}${search}`);
  };

  const handleBookNowButtonRender = () => {
    if (profileType === 'cust') return false;
    else {
      if (userOverViewData?.data?.user?.type === RoleType.service_provider) return true;
      else return false;
    }
  };

  const handleBookNowButtonDisabledState = () => {
    if (userIsViewingOwnProfile) return true;
    else if (
      searchParams.get('service_type') &&
      searchParams.get('service_id') &&
      searchParams.get('name') &&
      customerRole.includes(+cookie.role)
    ) {
      return false;
    }
    return true;
  };

  if (isLoading || verificationDataIsLoading || serviceDataIsLoading || userFeedbackDataIsLoading)
    return <GlobalLoader />;

  return (
    <>
      <Grid style={{ height: 'calc(100svh - 140px)' }} gutter={8} p={0} m={0}>
        <Grid.Col md={3} p={0} m={0}>
          <Box className={classes.firstGridColumnContainer}>
            <AddToFavourites
              renderIf={renderAddToFavourites()}
              favouritesIsLoading={favouritesIsLoading}
              isThisUserMyFavourite={+favouriteUserDetails?.['fav_user_id'] === +userId}
              handleFavouritesAddOrRemove={handleFavouritesAddOrRemove}
            />

            <ProfileCard
              profileData={userOverViewData?.data}
              verificationData={verificationData}
              userIsViewingOwnProfile={userIsViewingOwnProfile}
            />
            <Divider size='xs' my={10} />
            <UserBasicInfo data={userOverViewData?.data} />

            <Flex justify='center' align='center' my={20}>
              {handleBookNowButtonRender() && (
                <Button
                  size='md'
                  w='60%'
                  disabled={handleBookNowButtonDisabledState()}
                  variant='gradient'
                  sx={{ '&[data-disabled]': { pointerEvents: 'all' } }}
                  gradient={{ from: '#fda223', to: '#fe7720' }}
                  onClick={handleBookNow}>
                  Book Now
                </Button>
              )}
            </Flex>
          </Box>
        </Grid.Col>

        {profileType === 'prov' ? (
          <Grid.Col md={searchParams.get('service_type') ? 5 : 9} p={0} m={0}>
            <Box className={classes.secondGridColumnContainer}>
              {serviceData?.data.length ? (
                <TabElementDetails services={serviceData?.data} userFeedback={userFeedback} />
              ) : (
                <Flex justify='center' align='center' h='100%'>
                  <Title order={3} c='dimmed'>
                    No services
                  </Title>
                </Flex>
              )}
            </Box>
          </Grid.Col>
        ) : null}

        {profileType === 'cust' ? (
          <Grid.Col md={9} p={0} m={0}>
            <Box>
              <Title order={2} ml={5} mb={12}>
                Ratings
              </Title>
              <ScrollArea.Autosize>
                <ul>
                  {userFeedback?.map((review: any, index: number) => {
                    return (
                      <li key={index}>
                        <CommentCard
                          postedAt={review.created_at}
                          body={review.review}
                          rating={review.rating}
                          author={{
                            name: review?.has_user?.name,
                            image: review?.has_user?.user_profile?.image || '',
                          }}
                          verifiedUser={review?.has_user?.has_verification}
                        />

                        {index !== userFeedback.length - 1 && <Divider my='sm' />}
                      </li>
                    );
                  })}
                </ul>
              </ScrollArea.Autosize>
            </Box>
          </Grid.Col>
        ) : null}

        {searchParams.get('service_type') && searchParams.get('service_id') && profileType === 'prov' ? (
          <Grid.Col md={4} p={0} m={0}>
            <Box className={classes.thirdGridColumnContainer}>
              <RelatedProfiles title='Related User' id={searchParams.get('service_type')} />
            </Box>
          </Grid.Col>
        ) : null}
      </Grid>
    </>
  );
}
