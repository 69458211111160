import { Button, Card, Flex, Group, Text, useMantineTheme } from '@mantine/core';
import { upperFirst, useMediaQuery } from '@mantine/hooks';
import { IconCurrencyDollar, IconRoute, IconUserCircle, IconUserStar } from '@tabler/icons-react';
import { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { NavLink, useNavigate } from 'react-router-dom';
import ProfilePhoto from '../../../../../components/ProfilePhoto';
import { ThemeContext } from '../../../../../context/themeContext';
import { useSubscriptionLimitCheck } from '../../../../../services/subscription.api';
import { customerRole } from '../../../../../utils';
import generateRandomString from '../../../../../utils/helpers/generateRandomAlphabetStrings';
import { ICard } from '../utils/Card.interface';
import { useFreelanceStyles, useListCardRefactoredStyles } from './style';

export default function ListCardRefactored(props: ICard) {
  // console.log(props);

  const {
    image,
    name,
    description,
    email_verified_at,
    city,
    rate,
    service_id,
    service_type_id,
    users_id,
    total_reviews,
    distance_in_miles,
    service,
    type,
    backgrounnd_verified,
  } = props;
  const [cookie, setCookie] = useCookies(['role', 'user_Info']);
  const { classes } = useFreelanceStyles();
  const navigate = useNavigate();

  const { setChatType, setChannelId, setChatRecieverId, setSubscriptionExhausted, setSelectedServiceName } =
    useContext(ThemeContext);
  const { data: subscriptionLimitData } = useSubscriptionLimitCheck(true);

  useEffect(() => {
    if (subscriptionLimitData) {
      setSubscriptionExhausted(subscriptionLimitData);
    }
  }, [subscriptionLimitData]);

  const sendChatInfo = (serviceCreatorId: any) => {
    // if (subscriptionLimitData?.data?.chat_exausted) {
    //   toastMessageUpdate('info', subscriptionExhausted?.message);
    //   return;
    // }
    if (customerRole.includes(+cookie.role)) {
      setChatRecieverId(serviceCreatorId);
      setChannelId('' + serviceCreatorId + cookie?.user_Info?.id);
      setChatType('userChat');
      navigate('/chat');
    }
  };

  const navigateBookingPage = () => {
    setSelectedServiceName(service);
    navigate(
      `/book-services/${encodedIdWithString}?service_type=${service_type_id}&service_id=${service_id}&name=${btoa(
        service,
      )}`,
    );
  };

  const [encodedIdWithString, setEncodedIdWithString] = useState('');
  useEffect(() => {
    setEncodedIdWithString(btoa(generateRandomString(16) + users_id + generateRandomString(16)));
  }, []);

  const { classes: listCardRefactoredClasses, cx } = useListCardRefactoredStyles();

  const matches = useMediaQuery('(max-width: 750px)');
  const theme = useMantineTheme();
  return (
    <>
      <Card bg={theme.colorScheme === 'light' ? '#ECECEC' : theme.colors.dark[6]} radius='md' withBorder>
        <Flex justify='space-between' px={0}>
          <Flex align='center' gap={matches ? 10 : 15}>
            <ProfilePhoto
              src={image ? process.env.REACT_APP_IMAGE_URL + 'service_images/' + image : ''}
              type='renderWithoutEdit'
              sizes={{
                xl: 80,
                lg: 80,
                md: 80,
                sm: 100,
                xs: 70,
              }}
              showVerificationBorder={backgrounnd_verified}
            />
            <Flex className={listCardRefactoredClasses.flexContainerOne} gap={4}>
              <Text fw={600}>{upperFirst(name)}</Text>
              {distance_in_miles ? (
                <Group spacing={2}>
                  <IconRoute className='routeIcon' />
                  <Text lh={1} className='distanceText'>
                    {parseFloat(distance_in_miles).toFixed(2) === '0.00'
                      ? '0.00'
                      : parseFloat(distance_in_miles).toFixed(2)}{' '}
                    Miles Distance
                  </Text>
                </Group>
              ) : null}

              <Flex align='center' gap={4}>
                {props.type === 2 ? <IconUserCircle size={16} stroke={'#c1c1c1'} /> : <IconUserStar size={16} />}

                <Text lh={1} className='profileTypeText'>
                  {props.type === 2 ? 'Individual' : 'Organization'}
                </Text>
              </Flex>

              <Flex align='center'>
                <IconCurrencyDollar className='currencyIcon' />
                <Text lh={1} className='priceRateText'>
                  {rate}/hr
                </Text>
              </Flex>
            </Flex>

            {/* <Group className={listCardRefactoredClasses.avatar_group}>
              <Text fz={matches ? '12px' : '16px'} fw={600}>
                {upperFirst(name)}
              </Text>
              
              <Flex>
                <Flex className={listCardRefactoredClasses.km_text}>
                  {distance_in_miles !== null && (
                    <Flex align='center'>
                      <IconRoute size={matches ? '15px' : '18px'} />
                      <Text c='dimmed' size='xs'>
                        {parseFloat(distance_in_miles).toFixed(2) === '0.00'
                          ? '0.00'
                          : parseFloat(distance_in_miles).toFixed(2)}{' '}
                        Miles Distance
                      </Text>
                    </Flex>
                  )}
                </Flex>
              </Flex>
              
              <Flex>
                <Text fw={600}>$</Text>
                <Text fw={600} size={matches ? '15px' : '16px'}>
                  {rate}/hr
                </Text>
              </Flex>
              {props.type === 2 ? <IconUserCircle size={16} stroke={'#c1c1c1'} /> : <IconUserStar size={16} />}
            </Group> */}
          </Flex>
          <Flex direction={'column'} gap={5} className={classes.avatar_button} justify='space-between'>
            <Button
              variant='light'
              component={NavLink}
              compact
              to={`/prov/${encodedIdWithString}?service_type=${service_type_id}&service_id=${service_id}&name=${btoa(
                service,
              )}`}
              className={listCardRefactoredClasses.button_profile}
              w={'100%'}
              radius={4}>
              <Text c='orange.8'>View Profile</Text>
            </Button>
            <Button
              color='orange'
              variant='light'
              compact
              w={'100%'}
              className={listCardRefactoredClasses.button_profile}
              radius={4}
              onClick={() => {
                navigateBookingPage();
              }}>
              <Text c='orange.8'>Book Now</Text>
            </Button>
            <Button
              color='orange'
              variant='light'
              w={'100%'}
              compact
              className={listCardRefactoredClasses.button_profile}
              radius={4}
              onClick={() => {
                sendChatInfo(users_id);
              }}>
              <Text c='orange.8'>Interview</Text>
            </Button>
          </Flex>
        </Flex>
      </Card>
    </>
  );
}
