import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Flex,
  Grid,
  LoadingOverlay,
  PasswordInput,
  ScrollArea,
  Select,
  Table,
  Text,
  TextInput,
  Title,
  Tooltip,
  UnstyledButton,
  rem,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconChevronDown, IconLayoutGridAdd, IconUserMinus } from '@tabler/icons-react';
import { useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { SubmitHandler, useForm } from 'react-hook-form';
import { InferType } from 'yup';
import ErrorText from '../../../../../components/ErrorText';
import { createOrgUser, deleteOrgUser, orgStatus, useOrgUesrList } from '../../../../../services/organization.api';
import { getUserSpecificService } from '../../../../../services/service';
import { toastMessageUpdate } from '../../../../../utils/helpers/tosterMessage';
import { addUserSchema } from '../../../../../utils/schema/formSchema';
import ModalOrDrawer from '../../../Customer/Profile/components/ModalOrDrawer';
import CustomButton from './CustomButton';
import ShareService from './ShareService';
import { useAddUserStyles, useAddUserStylesScrollAreaStyles } from './style';

/**
 * This component is only valid for organisational provider
 */
export default function AddUser() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addUserSchema),
    mode: 'all',
  });

  const { classes, cx } = useAddUserStyles();
  const { classes: addUserStylesScrollAreaClasses } = useAddUserStylesScrollAreaStyles();
  const [addUserOpened, { open: openAddUser, close: closeAddUser }] = useDisclosure(false);
  const [deleteUserOpened, { open: openDeleteUser, close: closeDeleteUser }] = useDisclosure(false);
  const [shareServiceOpened, { open: openShareService, close: closeShareService }] = useDisclosure(false);
  const [cookies] = useCookies(['user_Info']);
  // console.log(cookies);
  const [addUserLoading, setAddUserLoading] = useState(false);
  const [deleteUserLoading, setDeleteUserLoading] = useState(false);
  const [userIdofOrganization, setUserIdofOrganization] = useState<Record<string, string>>({});
  const [organizationId, setorganizationId] = useState<string>('');
  // const [orguserData, setOrguserData] = useState<Record<string, any>>([]);

  const [shareServiceEmployeeName, setShareServiceEmployeeName] = useState('');

  useEffect(() => {
    getOrgId();
  }, []);

  const { data: orgUserData, isLoading, refetch } = useOrgUesrList(organizationId);

  const getOrgId = async () => {
    try {
      const statusData: any = await orgStatus(cookies?.user_Info?.id);
      // console.log(statusData);
      if (statusData.success) {
        setorganizationId(statusData?.data?.organization_id);
      }
    } catch (error: any) {
      console.log(error.status);
    }
  };

  const onSubmit: SubmitHandler<InferType<typeof addUserSchema>> = async (data) => {
    setAddUserLoading(true);
    const userData = {
      type: 2,
      organization_id: organizationId,
      name: data.firstName.trim().concat(' ', data.lastName.trim()),
      email: data.email.toLowerCase(),
      mobile: +data.mobile,
      password: data.password.trim(),
      password_confirmation: data.password.trim(),
    };
    try {
      const addUserData = await createOrgUser(userData);
      setAddUserLoading(false);
      toastMessageUpdate('success', 'User added successfully for your organization');
      reset({
        email: '',
        firstName: '',
        lastName: '',
        mobile: '',
        password: '',
      });
      closeAddUser();
      refetch();
    } catch (error: any) {
      setAddUserLoading(false);
      toastMessageUpdate('error', error?.response?.data?.message || '');
      console.log(error);
    }
  };

  const handleDeleteUser = async (formData: Record<string, string>) => {
    setDeleteUserLoading(true);
    try {
      const orgUserData: any = await deleteOrgUser(formData);
      if (orgUserData.success) {
        refetch();
        toastMessageUpdate('success', orgUserData?.message);
        setDeleteUserLoading(false);
      }
    } catch (error: any) {
      console.log(error.status);
      setDeleteUserLoading(false);
    }
    closeDeleteUser();
  };

  const getServices = async () => {
    try {
      const orgUser: any = await getUserSpecificService(cookies?.user_Info?.id);
      if (orgUser.success) {
        console.log('orgUser', orgUser);
      }
    } catch (error: any) {
      console.log(error.status);
    }
  };

  const handleShareService = () => {
    getServices();
    openShareService();
  };

  console.log(orgUserData?.data.length);

  const rows = useMemo(
    () =>
      orgUserData?.data?.map((element: any) => {
        return (
          <tr key={element.email}>
            <td>{element.name.split(' ')[0]}</td>
            <td>{element.name.split(' ')[1]}</td>
            <td>{element.mobile}</td>
            <td>{element.email}</td>
            <td>
              <Flex gap={4}>
                <Tooltip label='Share Service' color='#fe7720'>
                  <UnstyledButton
                    className={classes.buttonContainerForEdit}
                    onClick={() => {
                      setShareServiceEmployeeName(element);
                      handleShareService();
                    }}>
                    <IconLayoutGridAdd color='white' size={rem(16)} stroke={1.8} />
                  </UnstyledButton>
                </Tooltip>
                <Tooltip label='Delete User' color='#fe7720'>
                  <UnstyledButton
                    className={classes.buttonContainerForDelete}
                    onClick={() => {
                      openDeleteUser();
                      setUserIdofOrganization(element.pivot);
                    }}>
                    <IconUserMinus color='gray' size={rem(20)} stroke={1.8} />
                  </UnstyledButton>
                </Tooltip>
              </Flex>
            </td>
          </tr>
        );
      }),
    [
      orgUserData,
      // add dependencies here that should cause the useMemo to recompute the rows
    ],
  );

  return (
    <>
      <Box className={classes.container}>
        <Flex justify='space-between'>
          <Title order={2} c='orange.8' fw={600} mb={20}>
            Add Users
          </Title>

          <Button
            className={classes.addUserButton}
            variant='gradient'
            gradient={{ from: '#fda223', to: '#fe7720' }}
            onClick={openAddUser}
            compact>
            Add user
          </Button>
        </Flex>

        <ScrollArea.Autosize classNames={addUserStylesScrollAreaClasses}>
          <Table
            className={classes.table}
            verticalSpacing='sm'
            fontSize='md'
            pos='relative'
            sx={(theme) => ({
              'th:nth-of-type(1)': {
                width: '25%',
              },
              'th:nth-of-type(2)': {
                width: '25%',
              },
              'th:nth-of-type(3)': {
                width: '15%',
              },
              'th:nth-of-type(4)': {
                width: '25%',
              },
              'th:nth-of-type(5)': {
                width: '10%',
              },
            })}>
            <LoadingOverlay visible={isLoading} overlayBlur={2} />

            <thead
              style={{
                position: 'sticky',
                top: 0,
                // backgroundColor: '#f9f9f9',
              }}>
              <tr>
                <th>First name</th>
                <th>Last name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
          {orgUserData?.data.length === 0 ? (
            <Flex className={classes.noUserFlexContainer}>
              <Text>User does not exist</Text>
            </Flex>
          ) : null}
        </ScrollArea.Autosize>
      </Box>

      <ModalOrDrawer
        opened={addUserOpened}
        onClose={closeAddUser}
        closeOnClickOutside={false}
        drawerProps={{ dz: '70%', drawerBackButton: true }}
        modalProps={{ modalCloseButton: true }}>
        <Title order={2} ta='center'>
          Add User
        </Title>
        <Flex justify='center' direction='column'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid>
              <Grid.Col>
                <TextInput
                  {...register('firstName')}
                  size='md'
                  label='First name'
                  placeholder='Enter your first name'
                  spellCheck={false}
                />
                <ErrorText errorProperty={errors.firstName} mt={2} />
              </Grid.Col>
              <Grid.Col>
                <TextInput
                  {...register('lastName')}
                  size='md'
                  label='Last name'
                  placeholder='Enter your last name'
                  spellCheck={false}
                />
                <ErrorText errorProperty={errors.lastName} mt={2} />
              </Grid.Col>
              <Grid.Col>
                <Flex>
                  <Select
                    label='Phone'
                    labelProps={{ fz: 'md' }}
                    rightSection={<IconChevronDown size='1rem' style={{ cursor: 'pointer' }} />}
                    styles={(theme) => ({
                      wrapper: {
                        height: 42,
                      },
                      input: {
                        height: 42,
                        borderRadius: `${rem(8)} 0 0 ${rem(8)}`,
                        paddingRight: 0,
                        border: `0.0625rem solid #e1e1e1`,
                      },
                      rightSection: { pointerEvents: 'none' },
                    })}
                    w={'30%'}
                    data={[
                      { value: '+91', label: '+91' },
                      { value: '+1', label: '+1' },
                    ]}
                    defaultValue={'+1'}
                  />
                  <TextInput
                    w={'70%'}
                    size='md'
                    mt={'29.4px'}
                    {...register('mobile', { required: true })}
                    placeholder='Enter your number'
                    radius='md'
                    styles={(theme) => ({
                      input: {
                        borderRadius: `0 ${rem(8)} ${rem(8)} 0`,
                      },
                    })}
                  />
                </Flex>
                <ErrorText errorProperty={errors.mobile} mt={2} />
              </Grid.Col>
              <Grid.Col>
                <TextInput {...register('email')} label='Email' type='email' spellCheck={false} />
                <ErrorText errorProperty={errors.email} mt={2} />
              </Grid.Col>

              <Grid.Col>
                <PasswordInput {...register('password')} label='Password' error={!!errors.password} />
                <ErrorText errorProperty={errors.password} mt={2} />
              </Grid.Col>

              <Grid.Col xs={6} className={classes.buttonGridContainerForAddCancel}>
                <CustomButton variant='gradient' loading={addUserLoading} type='submit'>
                  Add
                </CustomButton>
              </Grid.Col>
              <Grid.Col xs={6} className={classes.buttonGridContainerForAddCancel}>
                <CustomButton
                  variant='outline'
                  onClick={() => {
                    reset();
                    closeAddUser();
                  }}>
                  Cancel
                </CustomButton>
              </Grid.Col>
            </Grid>
          </form>
        </Flex>
      </ModalOrDrawer>

      <ModalOrDrawer
        opened={deleteUserOpened}
        onClose={closeDeleteUser}
        drawerProps={{ dz: '30%', drawerBackButton: true, drawerOverlayProps: { opacity: 0, blur: 0 } }}
        modalProps={{ modalCloseButton: true }}>
        <Box>
          <Title order={2} ta='center'>
            Delete User
          </Title>
          <Text c='dimmed' ta='center'>
            Are you sure you want to delete this account?
          </Text>

          <Text c='red' ta='center' fz={14}>
            You will not be able to reverse this action
          </Text>
          <Grid p={0} m={0} mt={16}>
            <Grid.Col xs={6} className={classes.buttonGridContainerForAddCancel}>
              <CustomButton
                variant='outline'
                loading={deleteUserLoading}
                type='submit'
                onClick={() => handleDeleteUser(userIdofOrganization)}>
                Yes
              </CustomButton>
            </Grid.Col>
            <Grid.Col xs={6} className={classes.buttonGridContainerForAddCancel}>
              <CustomButton variant='gradient' onClick={closeDeleteUser}>
                No
              </CustomButton>
            </Grid.Col>
          </Grid>
        </Box>
      </ModalOrDrawer>

      <ModalOrDrawer
        opened={shareServiceOpened}
        onClose={closeShareService}
        drawerProps={{ dz: '30%', drawerBackButton: true, drawerOverlayProps: { opacity: 0, blur: 0 } }}
        modalProps={{ modalCloseButton: true }}>
        <ShareService employeeName={shareServiceEmployeeName} />
        {/* <ShareServiceRefactored employeeName={shareServiceEmployeeName} /> */}
      </ModalOrDrawer>
    </>
  );
}
